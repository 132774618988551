import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'
import { Stack } from '@mui/material'
import Dropdown from 'components/form/Dropdown'
import SearchText from 'components/form/SearchText'
import Button from 'components/form/button/Button'
import ButtonOutline from 'components/form/button/ButtonOutline'
import Table from 'components/common/Table'
import Date from 'components/form/Date'
import Badge from 'components/containers/Badge'
import BadgeStatus from 'components/containers/BadgeStatus'
import Modal from 'components/common/Modal'
import QrModalContent from './QrModalContent'
import ConfirmCancelActivityModalContent from './ConfirmCancelActivityModalContent'
import ConfirmDeleteActivityModalContent from './ConfirmDeleteActivityModalContent'
import SuccessModal from 'components/dialog/SuccessModal'
import {  DisplayTableActivityName,DateDisplayDDMMBBB } from 'utils/common'

import { FiPlus, FiTrash2 } from 'react-icons/fi'
import { LuPen } from 'react-icons/lu'

import demoQr from 'assets/images/demo-qr-code.png'

import { useDispatch } from 'react-redux'
import { cancelActivityCpd, deleteActivityCpd, getActivityCpd } from 'redux/actions/activityCpd'
import { useSelector } from 'react-redux'
import { selectActivityCPD } from 'redux/selectors'

const Div = styled.div`
  .add-activity-btn {
    border: 1px solid var(--success-600);
    background: var(--success-600);
  }
  .qr-image {
    width: 40px;
    height: 40px;
    &:hover {
      cursor: pointer;
    }
  }
  .highlight-text {
    &:hover {
      color: var(--COE-Main-CI);
      font-weight: 700;
      cursor: pointer;
    }
  }
  .cancel-activity {
    color: var(--COE-Main-CI);
    font-weight: 700;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .icon {
    color: var(--Gray-600);

    &:hover {
      cursor: pointer;
    }
  }
`
const InputContainer = styled.div`
  .heading {
    color: var(--Gray-700, #344054);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;

    margin-bottom: 6px;
  }

  .custom-width {
    min-width: 300px;
  }
`
const TableWrapper = styled.div`
  .header-table {
    padding: 26px 24px;
    border-radius: 8px 8px 0 0;
    border: 1px solid var(--Gray-200);
  }
  .heading-table {
    color: var(--Gray-900, #101828);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
`
function OrgActivityList() {
  // external hook
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // initiate data
  const fetchOrgActivity = useCallback((filter = { skip: 0, limit: 10, status: '', start_at: '', search: '' }) => {
    dispatch(getActivityCpd(filter))
  }, [])

  useEffect(() => {
    fetchOrgActivity()
  }, [])

  // qr modal
  const [isOpenQrModal, setIsOpenQrModal] = useState(false)

  const openQrModal = () => {
    setIsOpenQrModal(true)
  }
  const closeQrModal = () => {
    setIsOpenQrModal(false)
  }

  // activity id
  const [activityId, setActivityId] = useState('')
  const [activityData, setActivityData] = useState('')
  const [typeQr, setTypeQr] = useState('')

  const cancelActivity = (id) => {
    setActivityId(id)
    openConfirmCancelActivity()
  }
  const onEdit = (id) => {
    navigate(`/Activity/OrgActivity/${id}`)
  }
  const onDelete = (id) => {
    setActivityId(id)
    openConfirmDeleteActivity()
  }
  
  const openCheckOutQr = (value) => {
    setTypeQr('check-out')
    setActivityData(value)
    openQrModal()
  }
  const openCheckInQr = (value) => {
    setTypeQr('check-in')
    setActivityData(value)
    openQrModal()
  }
  const openRegistrationQr = (value) => {
    setTypeQr('register')
    setActivityData(value)
    openQrModal()
  }

  // table data
  const orgActivityColumns = [
    {
      Header: 'ลำดับ',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'รหัสกิจกรรม',
      accessor: 'code',
      disableSortBy: false,
    },
    {
      Header: 'ชื่อกิจกรรม',
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: 'สาขาวิศวกรรม',
      accessor: 'engineering_field_name',
      disableSortBy: false,
    },

    {
      Header: 'จำนวนผู้ลงทะเบียน',
      accessor: 'amount_registerd',
      disableSortBy: false,
    },
    {
      Header: 'วันที่จัดกิจกรรม',
      accessor: 'start_at',
      disableSortBy: false,
    },
    {
      Header: 'วันที่ทำรายการ',
      accessor: 'createdAt',
      disableSortBy: false,
    },
    {
      Header: 'สถานะ',
      accessor: 'status_name',
      disableSortBy: false,
    },
    {
      Header: 'Pre register',
      accessor: 'qr_url_preregister',
      disableSortBy: false,
    },
    {
      Header: 'Check-in',
      accessor: 'qr_url_checkin',
      disableSortBy: false,
    },
    {
      Header: 'Check-out',
      accessor: 'qr_url_checkout',
      disableSortBy: false,
    },
    {
      Header: '',
      accessor: 'cancel_activity',
      disableSortBy: false,
    },
    {
      Header: '',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const { activitiesCPD, limit, skip, total } = useSelector(selectActivityCPD)
  const [orgActivityList, setOrgActivityList] = useState([])
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  useEffect(() => {
    setPageCount(Math.ceil(total / limit))
  }, [limit, total])
  useEffect(() => {
    const newOrgActivityList = activitiesCPD.map((v, i) => ({
      ...v,
      no: skip + 1 + i,
      name: (
        <div className="highlight-text" onClick={() => onEdit(v.id)}>
          {DisplayTableActivityName(v.name)}
        </div>
      ),
      engineering_field_name: v.engineering_field_name ? v.engineering_field_name : '-',
      amount_registerd: `${v.participants_count}/${v.open_attendee_count}`,
      start_at: `${DateDisplayDDMMBBB(v.start_at)}`,
      createdAt: `${DateDisplayDDMMBBB(v.createdAt)}`,
      qr_url_preregister: (
        <img className="qr-image" src={demoQr} alt={`registration-qr-${v.id}`} onClick={() => openRegistrationQr(v)} />
      ),
      qr_url_checkin: (
        <img className="qr-image" src={demoQr} alt={`check-in-qr-${v.id}`} onClick={() => openCheckInQr(v)} />
      ),
      qr_url_checkout: (
        <img className="qr-image" src={demoQr} alt={`check-out-qr-${v.id}`} onClick={() => openCheckOutQr(v)} />
      ),
      cancel_activity:
        v.status_name !== 'ยกเลิก' ? (
          <div className="cancel-activity" onClick={() => cancelActivity(v.id)}>
            ยกเลิกกิจกรรม
          </div>
        ) : (
          ''
        ),
      status_name: <BadgeStatus status={v.status_name} />,
      action: (
        <Stack direction="row" spacing={2}>
          <FiTrash2 className="icon" size={18} onClick={() => onDelete(v.id)} />
          <LuPen className="icon" size={18} onClick={() => onEdit(v.id)} />
        </Stack>
      ),
    }))
    setOrgActivityList(newOrgActivityList)
  }, [activitiesCPD])

  // options dropdown
  const initialStatusOptions = [
    {
      text: 'ยกเลิก',
      value: '-2',
    },
    {
      text: 'ไม่อนุมัติ',
      value: '-1',
    },
    {
      text: 'รอการพิจารณา',
      value: '0',
    },
    {
      text: 'ปกติ',
      value: '1',
    },
    {
      text: 'ขอเอกสารเพิ่มเติม',
      value: '2',
    },
    {
      text: 'แก้ไขรายละเอียดกิจกรรม',
      value: '3',
    },
    {
      text: 'ร่าง',
      value: '4',
    },
  ]

  // filter
  const initialFilter = {
    search: '',
    status: '',
    date: '',
  }
  const [filter, setFilter] = useState(initialFilter)

  const onChangePagination = (value) => {
    setPage(value.page)

    const request = {
      skip: (value.page - 1) * limit,
      limit,
      search: filter.search,
      status: filter.status,
      start_at: filter.date,
    }

    fetchOrgActivity(request)
  }
  const onChangeFilter = (name, value) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const onSearch = () => {
    const request = {
      skip: 0,
      limit,
      search: filter.search,
      status: filter.status,
      start_at: filter.date,
    }

    fetchOrgActivity(request)
    setPage(1)
  }
  const onClearSearch = () => {
    const request = {
      skip: 0,
      limit,
      search: '',
      status: '',
      start_at: '',
    }

    fetchOrgActivity(request)
    clearFilter()
  }
  const clearFilter = () => {
    setFilter(initialFilter)
  }

  // confirm cancel activity
  const [isOpenConfirmCancelActivity, setIsOpenConfirmCancelActivity] = useState(false)

  const openConfirmCancelActivity = () => {
    setIsOpenConfirmCancelActivity(true)
  }
  const closeConfirmCancelActivity = () => {
    setIsOpenConfirmCancelActivity(false)
  }

  // confirm cancel activity
  const [isOpenConfirmDeleteActivity, setIsOpenConfirmDeleteActivity] = useState(false)

  const openConfirmDeleteActivity = () => {
    setIsOpenConfirmDeleteActivity(true)
  }
  const closeConfirmDeleteActivity = () => {
    setIsOpenConfirmDeleteActivity(false)
  }

  // confirm cancel activity
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false)

  const openSuccessModal = () => {
    setIsOpenSuccessModal(true)
  }
  const closeSuccessModal = () => {
    setIsOpenSuccessModal(false)
  }

  // isLoading,state
  const { isLoading, state } = useSelector(selectActivityCPD)

  useEffect(() => {
    if (isLoading === false && state === 'DELETE_ACTIVITY_CPD.SUCCESS') {
      openSuccessModal()
    }
    if (isLoading === false && state === 'CANCEL_ACTIVITY_CPD.SUCCESS') {
      openSuccessModal()
    }
  }, [isLoading, state])

  // go to create page
  const createOrgActivity = () => {
    navigate('/Activity/OrgActivity/create')
  }

  // submit function
  const onSubmitDelete = () => {
    const request = {
      status: -99,
    }

    dispatch(deleteActivityCpd(activityId, request))
    closeConfirmDeleteActivity()
  }
  const onSubmitCancel = (value) => {
    const request = {
      status: -2,
      staff_comment: value,
    }

    dispatch(cancelActivityCpd(activityId, request))
    closeConfirmCancelActivity()
  }

  return (
    <Div>
      <Stack sx={{ mb: 2 }} direction="row" alignItems="flex-end" spacing={2}>
        <InputContainer>
          <div className="heading">ค้นหาด้วยชื่อหรืออีเมล</div>
          <SearchText
            className="custom-width"
            placeholder="Search ชื่อกิจกรรม รหัสกิจกรรม"
            value={filter.search}
            onChange={(e) => onChangeFilter('search', e.target.value)}
            // onSubmit={onSearch}
            onClear={() => onChangeFilter('search', '')}
          />
        </InputContainer>
        <InputContainer>
          <div className="heading">สถานะ</div>
          <Dropdown
            optionList={initialStatusOptions}
            value={filter.status}
            onChange={(value) => onChangeFilter('status', value)}
          />
        </InputContainer>
        <InputContainer>
          {/* <div className="heading">สถานะ</div> */}
          <Date value={filter.date} onChange={(value) => onChangeFilter('date', value)} />
        </InputContainer>

        <Button onClick={onSearch}>ค้นหา</Button>
        <ButtonOutline onClick={onClearSearch}>ล้างการค้นหา</ButtonOutline>
      </Stack>

      <TableWrapper>
        <Stack className="header-table" direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={1}>
            <div className="heading-table"> {'จำนวนกิจกรรมทั้งหมด'}</div>
            <Badge>{total} รายการ</Badge>
          </Stack>
          <Button append={<FiPlus size={18} />} className="add-activity-btn" onClick={createOrgActivity}>
            เพิ่มกิจกรรม
          </Button>
        </Stack>
        <Table
          data={orgActivityList}
          columns={orgActivityColumns}
          page={page}
          pageCount={pageCount}
          onStateChange={onChangePagination}
        />
      </TableWrapper>

      {/* Modal */}
      <Modal open={isOpenQrModal} onClose={closeQrModal} disableBackdropClick width={'512px'}>
        <QrModalContent type={typeQr} data={activityData} onClose={closeQrModal} />
      </Modal>
      <Modal
        open={isOpenConfirmCancelActivity}
        onClose={closeConfirmCancelActivity}
        disableBackdropClick
        width={'400px'}
      >
        <ConfirmCancelActivityModalContent onClose={closeConfirmCancelActivity} onSubmit={onSubmitCancel} />
      </Modal>
      <Modal
        open={isOpenConfirmDeleteActivity}
        onClose={closeConfirmDeleteActivity}
        disableBackdropClick
        width={'400px'}
      >
        <ConfirmDeleteActivityModalContent onClose={closeConfirmDeleteActivity} onSubmit={onSubmitDelete} />
      </Modal>
      <Modal open={isOpenSuccessModal} onClose={closeSuccessModal} disableBackdropClick width={'400px'}>
        <SuccessModal
          heading="ทำรายการสำเร็จ"
          textYes={'ตกลง'}
          onSubmit={closeSuccessModal}
          onClose={closeSuccessModal}
        />
      </Modal>
    </Div>
  )
}

export default OrgActivityList
