import { getAPI, postAPI, patchAPI } from 'utils/api'
import {
  GET_ACTIVITY_CPD,
  GET_ACTIVITY_CPD_BY_ID,
  CREATE_ACTIVITY_CPD,
  EDIT_ACTIVITY_CPD,
  DELETE_ACTIVITY_CPD,
  CANCEL_ACTIVITY_CPD,
  GET_REGISTERED_NAME_LIST,
  GET_INSTRUCTORS,
  GET_EVENTS,
  POST_EVENTS,
  UPDATE_EVENTS
} from 'redux/actionTypes'
import { showLoading, hideLoading } from 'redux/actions/loading.js'

export const getActivityCpd =
  (filter = { skip: 0, limit: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_ACTIVITY_CPD.REQUEST })

      const accessToken = localStorage.getItem('accessToken')
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }

      const params = {
        $skip: filter.skip,
        $limit: filter.limit,
        '$sort[id]': -1,
      }


      if (filter?.status && filter?.status !== '') params['status'] = filter.status
      if (filter?.start_at && filter?.start_at !== '') params['start_at'] = filter.start_at
      if (filter?.search && filter?.search !== '') {
        params['$or[0][name][$like]'] = `%${filter.search}%`
        params['$or[1][code][$like]'] = `%${filter.search}%`
      }
      /*
      const { data, limit, skip, total } = await getAPI({
        url: '/api/events/org_vw_events_org',
        headers,
        params,
      })

      return dispatch({ type: GET_ACTIVITY_CPD.SUCCESS, data, limit, skip, total })
      */

      const data_result = await getAPI({
        url: '/api/events/org_vw_events_org',
        headers,
        params,
      })

      return dispatch({ type: GET_ACTIVITY_CPD.SUCCESS, data : data_result.data, limit : data_result.limit, skip : data_result.skip, total : data_result.total, data_info: data_result })
      
    } catch (err) {
      return dispatch({
        type: GET_ACTIVITY_CPD.FAILURE,
        error: err,
        showAlert: true,
        alertMessage: 'Get Activity CPD Failure',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getActivityCpdBYId = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ACTIVITY_CPD_BY_ID.REQUEST })

    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    const data = {
      event_id: id,
    }

    const { data_info } = await postAPI({
      url: '/api/events/ORGGetEventInfo',
      data,
      headers,
    })

    return dispatch({ type: GET_ACTIVITY_CPD_BY_ID.SUCCESS, data: data_info })
  } catch (err) {
    return dispatch({
      type: GET_ACTIVITY_CPD_BY_ID.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Get Activity CPD By Id Failure',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createActivityCpd = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_ACTIVITY_CPD.REQUEST })

    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }

    const response = await postAPI({
      url: '/api/events/ORGEvents',
      data,
      headers,
    })

    dispatch({ type: CREATE_ACTIVITY_CPD.SUCCESS })
    return {
      code: response.code,
    }
  } catch (err) {
    return dispatch({
      type: CREATE_ACTIVITY_CPD.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Create Activity CPD Failure',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const editActivityCpd = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_ACTIVITY_CPD.REQUEST })

    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }

    await patchAPI({
      url: `/api/events/ORGEvents/${id}`,
      data,
      headers,
    })

    return dispatch({ type: EDIT_ACTIVITY_CPD.SUCCESS })
  } catch (err) {
    return dispatch({
      type: EDIT_ACTIVITY_CPD.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Edit Activity CPD Failure',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteActivityCpd = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_ACTIVITY_CPD.REQUEST })

    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }

    await patchAPI({
      url: `/api/events/ORGEvents/${id}`,
      data,
      headers,
    })

    return dispatch({ type: DELETE_ACTIVITY_CPD.SUCCESS })
  } catch (err) {
    return dispatch({
      type: DELETE_ACTIVITY_CPD.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Delete Activity CPD Failure',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getActivityCpd())
  }
}

export const cancelActivityCpd = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CANCEL_ACTIVITY_CPD.REQUEST })

    const accessToken = localStorage.getItem('accessToken')
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }

    await patchAPI({
      url: `/api/events/ORGEvents/${id}`,
      data,
      headers,
    })

    return dispatch({ type: CANCEL_ACTIVITY_CPD.SUCCESS })
  } catch (err) {
    return dispatch({
      type: CANCEL_ACTIVITY_CPD.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Cancel Activity CPD Failure',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getActivityCpd())
  }
}

export const getRegisteredNameList =
  (
    event_id,
    is_member_coe,
    filter = {
      skip: 0,
      limit: 0,
    }
  ) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_REGISTERED_NAME_LIST.REQUEST })

      const accessToken = localStorage.getItem('accessToken')
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }

      const params = {
        $skip: filter.skip,
        $limit: filter.limit,
        '$sort[id]': 1,
        event_id: event_id,
        is_member_coe,
      }

      const { data, skip, limit, total } = await getAPI({
        url: `/api/events/ORGEvent_participants`,
        headers,
        params,
      })
      if(filter.limit == 10000){ //--- กรณี Dowload Excel
        return { type: 'getRegisteredNameList_SUCCESS', data: data }
      }else{
        return dispatch({ type: GET_REGISTERED_NAME_LIST.SUCCESS, data, skip, limit, total, is_member_coe })
      }
    } catch (err) {
      return dispatch({
        type: GET_REGISTERED_NAME_LIST.FAILURE,
        error: err,
        showAlert: true,
        alertMessage: 'Get Registered name list Failure',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getInstructorNameList =
  (event_id, { filter = { skip: 0, limit: 10 } }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_INSTRUCTORS.REQUEST })

      const accessToken = localStorage.getItem('accessToken')
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }

      const params = {
        $skip: filter.skip,
        $limit: filter.limit,
        '$sort[id]': 1,
        event_id,
      }

      const { data, skip, limit, total } = await getAPI({
        url: `/api/events/ORGEvent_instructors`,
        headers,
        params,
      })

      return dispatch({ type: GET_INSTRUCTORS.SUCCESS, data, skip, limit, total })
    } catch (err) {
      return dispatch({
        type: GET_INSTRUCTORS.FAILURE,
        error: err,
        showAlert: true,
        alertMessage: 'Get Instructor name list Failure',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiGetVW_event_user_compare  = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_EVENTS.REQUEST })
  
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
      }
      if(filter.event_id){
        params = {...params,'event_id': filter.event_id}
      }
      if(filter.status_id){
        params = {...params,'status_id': filter.status_id}
      }
  
      const data_result = await getAPI({
        url: '/api/events/org_vw_event_user_compare?$sort[id]=1',
        headers: headers,
        params,
      })
  
      return { type: 'ApiGetVW_event_user_compare_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'ApiGetVW_event_user_compare_ERROR' }
  
    } finally {
      //dispatch(hideLoading())
    }
  }

  export const ApiEventCompareUser = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: POST_EVENTS.REQUEST })
  
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
  
      const data_result = await  postAPI({
        url: `/api/events/OrgEventCompareUser`,
        data,
        headers: headers,
      })
      
      return { type: 'ApiEventCompareUser_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'ApiEventCompareUser_ERROR' }
  
    } finally {
      //dispatch(hideLoading())
    }
  }
  
  export const ApiAdjuctEventUser = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: POST_EVENTS.REQUEST })
  
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
  
      const data_result = await  postAPI({
        url: `/api/events/OrgAdjuctEventUser`,
        data,
        headers: headers,
      })
      
      return { type: 'ApiAdjuctEventUser_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'ApiAdjuctEventUser_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiUpdateUserActivityPoint = (id, data) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: UPDATE_EVENTS.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      await patchAPI({
        url: `/api/member/ORGUserActivityPoint/${id}`,
        data,
        headers: headers,
      })
  
      return { type: 'ApiUpdateUserActivityPoint_SUCCESS', data_info: { code : 200 } }
  
    } catch (err) {
  
      return { type: 'ApiUpdateUserActivityPoint_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
  }

  export const ApiGetVWEventCheckIn  = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_EVENTS.REQUEST })
  
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
      }
  
      if(filter.event_id){
        params = {...params,'event_id': filter.event_id}
      }
  
      if(filter.activity_date){
        params = {...params,'activity_date': filter.activity_date}
      }
  
  
      const data_result = await getAPI({
        url: '/api/events/ORGEvent_checkin?$sort[id]=-1',
        headers: headers,
        params,
      })
  
      return { type: 'ApiGetVWEventCheckIn_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'AApiGetVWEventCheckIn_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
  }
